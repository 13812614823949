import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect:"/shouye"
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect:"/shouye",
    children: [
      {
        path: '/shouye',
        name: 'shouye',
        component: () => import('../views/shouye.vue')
      },
      {
        path: '/address',
        name: 'address',
        component: () => import('../views/address.vue')
      },
      {
        path: '/guanyu',
        name: 'guanyu',
        component: () => import('../views/guanyu.vue')
      },
      {
        path: '/ceping',
        name: 'ceping',
        component: () => import('../views/ceping.vue'),
        redirect:"/shouye/test",
        children:[
          {
            path: '/shouye/test',
            name: 'test',
            component: () => import('../views/cepingchild/test.vue')
          },
          {
            path: '/shouye/type',
            name: 'type',
            component: () => import('../views/cepingchild/type.vue')
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
