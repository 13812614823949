<template>
  <div class="home">
    <div class="navbar">
      <div class="left-top">
        <img src="../assets/image/logo.png" alt="" />
      </div>
      <div class="right-top">
        <ul>
          <li
            @click="clickli(i)"
            :class="[i.name == activeli ? 'active' : '']"
            v-for="i in navlists"
            :key="i.name"
          >
            <router-link :to="i.dizhi">{{ i.name }}</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="home-center">
      <div class="home-center-child">
        <transition name="slide-fade">
          <router-view></router-view>
        </transition>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },

  data() {
    return {
      activeli: "首页",
      navlists: [
        {
          name: "首页",
          dizhi:'/shouye'
        },
        {
          name: "骨骼测评",
           dizhi:'/ceping'
        },
        {
          name: "关于福音",
           dizhi:'/guanyu'
        },
        {
          name: "联系我们",
           dizhi:'/address'
        },
      ],
    };
  },
  created() {},
  methods: {
    clickli(i) {
      this.activeli = i.name;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .1s ;
}
.slide-fade-leave-active {
  transition: all .1s ;
}
.slide-fade-enter{
  // transform: translateX(10px);
  transition: all .3s ;
  opacity: 0;
}
.slide-fade-leave-to {
  // transform: translateX(10px);
  transition: all .3s ;
  opacity: 0;
}
.home {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 80px;
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    width: 100%;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 3px 10px 0 #d2d2d2;
    // background-color: pink;
    .left-top {
      display: flex;
      justify-content: center;
      width: 50%;
      img {
        margin-top: 15px;
        width: 112px;
        height: 49px;
      }
    }
    .right-top {
      width: 50%;
      ul {
        display: flex;
        width: 100%;
        height: 70px;
        line-height: 70px;
        li {
          cursor: pointer;
          font-size: 16px;
          color: #9d9d9d;
          width: 100px;
          text-align: center;
          justify-content: center;
          margin: 0 4%;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: #9d9d9d;
          }
        }
        .active {
          border-bottom: 2px solid #839a35;
          a {
            color: #839a35;
          }
        }
      }
    }
  }
  .home-center {
    width: 100%;
    height: 100%;
    // overflow: hidden;
    .home-center-child {
      width: calc(100% + 0px);
      height: 100%;
      // overflow: auto;
    }
  }
  .home-btn {
    p {
      text-align: center;
      color: #9D9D9D;
    }
    >a {
      display: inline-block;
      width: 100%;
      text-align: center;
      color: #9D9D9D;
    }
    .beian{
      display: flex;
      width: 100%;
      justify-content: center;
      text-align: center;
      a{
        color: #9D9D9D;
        
      }
    }
  }
}
</style>
